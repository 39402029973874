var LoginView = require( './form-view' );
var LoginModel = require( './form-model' );

module.exports = Marionette.Object.extend( {
  initialize: function( options ) {
    var paymentProviderHandle = '';
    var paymentProviderName = '';
    var inviteEmail = '';
    var signUpMessage = '';

    if ( !_.isNull( window.model ) && !_.isUndefined( window.model ) ) {
      var lc = window.model.laborChannel;
      paymentProviderName = lc && lc.paymentProviders[ 0 ].name;
      paymentProviderHandle = lc && lc.paymentProviders[ 0 ].handle;
      if ( paymentProviderHandle === 'mturk' ) paymentProviderHandle = 'amazon';
      inviteEmail = window.model.recipient && window.model.recipient.emailAddress;
      signUpMessage = lc && lc.signUpMessage;
    }

    if ( !_.isNull( window.model ) && !_.isUndefined( window.model ) ) {
      this.model = new LoginModel( {
        signupEmail: inviteEmail,
        signUpMessage: signUpMessage,
        paymentProviderHandle: paymentProviderHandle,
        paymentProviderName: paymentProviderName
      } );
    } else {
      this.model = new LoginModel( {
        signupEmail: '',
        signUpMessage: '',
        paymentProviderHandle: 'paypal',
        paymentProviderName: 'paypal'
      } );
    }

    this.view = new LoginView( {
      model: this.model
    } );
  },

  show: function() {
    App.mainRegion.show( this.view );
  }
} );
