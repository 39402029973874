var ProfileModel = require( '../profile/basic-profile-model' );

module.exports = Marionette.ItemView.extend( {
  template: '#login-template',
  className: 'login-form bordered',
  tagName: 'div',
  modelEvents: {
    'sync': 'onSync',
    'error': 'onError',
    'invalid': 'onInvalidField'
  },
  ui: {
    signupForm: '#signup-form',
    submitLogin: '#submitLogin',
    submitSignup: '#submitSignup'
  },
  events: {
    'change input': 'onInputChange',
    'keyup input': 'onKeyup',
    'click @ui.submitLogin': 'submitLogin',
    'click @ui.submitSignup': 'submitSignup',
    'focus input': 'moveLabels',
    'focusout input': 'removeFocused'
  },
  initialize: function() {
    Backbone.Validation.bind( this );
  },
  onRender: function() {
    var fields = this.$( 'form#signup-form input.form-control' );
    // move label up if input not empty
    for ( var i = 0; i < fields.length; i++ ) {
      if ( $( fields[ i ] ).val() != '' ) {
        $( fields[ i ] ).parent().addClass( 'focused' );
      }
    }
  },
  onKeyup: function( e ) {
    var attr = $( e.target ).attr( 'name' );
    if ( !this.model.get( attr ) ) {
      return;
    }
    var val = $( e.target ).val();
    var error = this.model.preValidate( attr, val );
    var hasError = error ? true : false;
    $( e.target ).toggleClass( 'invalid', hasError );
    this.toggleValidationError( attr, error );
  },
  moveLabels: function( e ) {
    $( e.target ).parent().addClass( 'focused' );
  },
  removeFocused: function( e ) {
    if ( $( e.target ).val() == '' ) {
      $( e.target ).parent().removeClass( 'focused' );
    } else if ( $( e.target ).hasClass( 'invalid' ) ) {
      $( e.target ).parent().addClass( 'red' );
    } else if ( !$( e.target ).hasClass( 'invalid' ) ) {
      $( e.target ).parent().removeClass( 'red' );
    }
  },
  onInputChange: function( e ) {
    var attr = $( e.target ).attr( 'name' );
    var val = $( e.target ).attr( 'type' ) == 'checkbox' ? $( e.target ).prop( 'checked' ) : $( e.target ).val();
    this.model.set( attr, val );
  },
  submitLogin: function( e ) {
    e.preventDefault();
    this.ui.submitLogin.addClass( 'disabled' ).button( 'submitting' );
    Backbone.fetchCache.clearItem( '/api/bookmarks' );
    Backbone.fetchCache.clearItem( '/api/profile' );
    this.model.submitLogin();
  },
  submitSignup: function( e ) {
    e.preventDefault();
    Backbone.fetchCache.clearItem( '/api/bookmarks' );
    Backbone.fetchCache.clearItem( 'basicProfileInfo' );
    this.ui.submitSignup.addClass( 'disabled' ).button( 'submitting' );
    if ( this.model.signupIsValid() ) {
      this.ui.signupForm.submit();
    }
  },
  toggleValidationError: function( field, error ) {
    this.$el.find( '#' + field ).parent().next( '.field-error' ).remove();
    if ( error ) {
      if ( field === 'termsofservice' ) {
        this.$el.find( '#' + field ).parents( '.form-group.checkbox-group' ).next( '.field-error-checkbox' ).remove();
        return this.$el.find( '#' + field ).parents( '.form-group' ).after( '<span class="text-danger field-error-checkbox">' + error + '</span>' );
      }
      this.$el.find( '#' + field ).parent().after( '<span class="text-danger field-error">' + error + '</span>' );
    }
  },
  onInvalidField: function( model, errors ) {
    this.ui.submitLogin.removeClass( 'disabled' ).button( 'reset' );
    this.ui.submitSignup.removeClass( 'disabled' ).button( 'reset' );
    this.$el.find( '.field-error' ).remove();
    _.each( errors, function( val, key ) {
      this.toggleValidationError( key, val )
    }, this );
  },
  onSync: function( a, b, c ) {
    window.location = '/dashboard';
  },
  onError: function( message, type ) {
    this.ui.submitLogin.removeClass( 'disabled' ).button( 'reset' );
    this.ui.submitSignup.removeClass( 'disabled' ).button( 'reset' );
    if ( type == 'login' ) {
      $( '#login .alert-danger' ).removeClass( 'hidden' );
      $( '#login .error-msg' ).removeClass( 'hidden' );
      $( '#login .alert-danger .error-msg' ).html( message );
    } else {
      $( '#signup .alert-danger' ).removeClass( 'hidden' );
      $( '#signup .alert-danger .error-msg' ).html( message );
    }
  },
  templateHelpers: function() {
    return {
      laborChannelHandle: function() {
        var lc = window.model.laborChannel;
        var providers = lc && lc.paymentProviders;
        if ( !providers || !providers.length ) return null;
        return providers[ 0 ].handle === 'odesk' ? 'upwork' : providers[ 0 ].handle;
      }
    }
  }
} );
