var url = require( '../helpers/url-parser' );
var ProfileModel = require( '../profile/basic-profile-model' );
module.exports = Backbone.Model.extend( {
  defaults: {
    paymentProviderHandle: 'paypal',
    paymentProviderName: 'PayPal',
    signUpMessage: null,
    inviteEmail: null,
    username: null,
    password: null,
    signupPassword: null,
    signupEmail: null,
    confirmPassword: null,
    firstName: null,
    lastName: null,
    termsofservice: false
  },
  signupIsValid: function() {
    return this.isValid( [ 'signupPassword', 'signupEmail', 'confirmPassword', 'firstName', 'lastName', 'termsofservice' ] );
  },
  submitLogin: function() {
    var loginFields = {
      password: this.get( 'password' ),
      username: this.get( 'username' ),
      provider: "onespace",
      servicecode: "WS"
    };
    var self = this;
    var default_error = 'We can\'t find that email address and password combination. For your protection, we will lock your account after too many unsuccessful login attempts. Passwords are case sensitive.';
    var lockout = 'Unfortunately, your account has been locked after too many failed login attempts.';
    if ( this.isValid( [ 'password', 'username' ] ) ) {
      $.post( '/api/login', loginFields ).success( function( res ) {
        if ( res.success ) {
          var nextPage = url.getParamValue( 'next' );
          window.location.replace( nextPage && url.isRelativeUrl(nextPage) ? url.addParam(nextPage, {fresh: '1'}) : '/dashboard?fresh=1' );
        } else {
          self.trigger( 'error',  default_error, 'login' );
        }
      } ).error( function( response ) {
        var message = '';
        switch ( response.responseJSON.code ) {
          case 401:
            message = default_error;
            break;
          case 403:
            message = lockout
            break;
          default:
            message = default_error;
            break;
        }
      
        self.trigger( 'error', message, 'login' );
      } )
    }
  },
  validation: {
    signupEmail: [ {
      required: true,
      msg: "Please enter an email address."
        }, {
      pattern: "email",
      msg: "Please enter a valid email address."
        }, {
      minLength: 6,
      msg: "Please enter an email address with at least 6 characters."
        }, {
      maxLength: 64,
      msg: "Please enter an email address with no more than 64 characters."
        } ],
    signupPassword: [ {
      required: true,
      msg: "Please enter a password."
        },  {
      pattern: /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W\_])|(?=.*[\W\_])(?=.*\d))|(?=.*[\W\_])(?=.*[A-Z])(?=.*\d)).{8,}$/,
      msg: "Password must be at least 8 characters and contain at least three of the following four categories: digits, uppercase letters, lowercase letters, and non-alphanumeric symbols."
        } ],
    confirmPassword: [ {
      required: true,
      msg: "Please enter a confirmation password."
        }, {
      equalTo: 'signupPassword',
      msg: "Confirmation password must match password."
        } ],
    firstName: [ {
      required: true,
      msg: "Please enter first name."
        }, {
      maxLength: 255,
      msg: "Please use no more than 255 characters."
        } ],
    lastName: [ {
      required: true,
      msg: "Please enter last name."
        }, {
      maxLength: 255,
      msg: "Please use no more than 255 characters."
        } ],
    username: [ {
      required: true,
      msg: "Please enter a valid email address."
        }, {
      pattern: 'email',
      msg: "Please enter a valid email address."
        }, {
      minLength: 6,
      msg: "Please enter an email address with at least 6 characters."
        }, {
      maxLength: 64,
      msg: "Please enter an email address with no more than 64 characters."
        } ],
    password: {
      required: true,
      msg: "Please enter a valid password."
    },
    termsofservice: {
      acceptance: true,
      msg: 'Please accept our <a class="thick" href="http://www.onespace.com/terms-of-service/" target="_blank">Terms of Service.</a>'
    }
  }
} );
